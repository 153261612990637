<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="12" md="6"> LISTAGEM DE CHAVES </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisa"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-divider dark></v-divider>
    <v-data-table
      dense
      sort-by="Ordenar por"
      :headers="headers"
      :items="keys"
      :items-per-page="10"
      class="elevation-1"
      :loading="loading"
      :search="search"
      loading-text="Carregando... Por favor aguarde"
      :header-props="{
        sortByText: 'Ordenar por',
      }"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageAllText: 'Todos',
        'items-per-page-text': 'Chaves por pagina',
      }"
    >
      <template v-slot:no-results> Nenhuma chave encontrado </template>
      <template v-slot:item.dueDate="{ item }">
        {{ dateFormatted(item.dueDate) }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ dateFormatted(item.createdAt) }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de chaves</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import moment from "moment";
export default {
  name: "ListingKey",
  data() {
    return {
      keys: [],
      loading: false,
      search: "",
      headers: [
        { text: "#", value: "id" },
        { text: "Cliente", align: "start", value: "client.name" },
        { text: "CNPJ", value: "client.cnpj", sortable: false },
        { text: "Controle", value: "control", sortable: false },
        { text: "Produto", value: "program", sortable: false },
        { text: "Usuário", value: "user.name" },
        { text: "Chave", value: "key", sortable: false },
        { text: "Emissão", value: "createdAt" },
        { text: "Validade", value: "dueDate" },
      ],
    };
  },
  methods: {
    getKeys() {
      this.loading = true;
      const url = `${baseApiUrl}/keys`;
      axios.get(url).then((res) => {
        this.keys = res.data;
        this.loading = false;
      });
    },
    dateFormatted(due) {
      return moment(due).format("DD/MM/yyyy");
    },
  },
  mounted() {
    this.getKeys();
  },
};
</script>
<style>
@media (max-width: 599px) {
  .v-data-footer__select {
    margin-left: 0 !important;
    margin-right: 50px !important;
  }
  .v-data-footer__pagination {
    margin-left: 30px !important;
  }
}
</style>